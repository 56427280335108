<template>
  <div>
    <div class="header w-1920">
      <div class="img w-1200">
        <img src="../../assets/login/logo.gif" v-locales="lang.en" alt="" />
        <img src="../../assets/login/logo.gif" v-locales="lang.zh" alt="" />
        <div class="home" @click="home">{{ $t('common.home') }}</div>
        <!-- <div>{{ $m.fromNow("2023/01/02", "yyyy/MM/dd") }}</div> -->
        <locales style="position: absolute; right: 0px"></locales>
      </div>
    </div>
    <div class="content w-1920">
      <img src="../../assets/login/nav.png" alt="" />
      <div class="login">
        <div class="login_password">
          <div class="qiehuan">
            <div
              class="daohang"
              :class="[{ bian: index == TabIndex }, { 'border-right-line': index != TabList.length - 1 }]"
              v-for="(item, index) in paihang"
              :key="index"
              @click="selectedTabItem(index, item.id)"
            >
              {{ item.title }}
            </div>
          </div>
          <div v-if="TabIndex == 0">
            <div class="user">
              <el-input v-model="Register.phone" placeholder="手机号码/会员名/邮箱" clearable>
                <template slot="prepend">+86</template>
              </el-input>
            </div>
            <div class="user-pas">
              <el-form onsubmit="return false">
                <el-form-item prop="验证码" class="code">
                  <el-input v-model="Register.sendcode1" placeholder="请输入验证码" @keyup.enter.native="login"></el-input>
                  <div class="user-assets-text" v-if="loding">
                    <el-button type="button"><i class="el-icon-loading"></i></el-button>
                  </div>
                  <div v-else>
                    <el-button type="button" @click="sendcode" :disabled="disabled" v-if="disabled == false">发送验证码</el-button>
                    <el-button type="button" @click="sendcode" :disabled="disabled" v-if="disabled == true">{{ btntxt }}</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div v-if="TabIndex == 1">
            <div class="user">
              <el-input v-model="input" placeholder="手机号码/会员名/邮箱" clearable prefix-icon="el-icon-user"></el-input>
            </div>
            <div class="user-pas">
              <el-input show-password v-model="password" placeholder="密码" prefix-icon="el-icon-unlock" @keyup.enter.native="login"></el-input>
            </div>
            <div class="caozuo">
              <div class="right">
                <div class="mima" @click="forget">忘记密码</div>
              </div>
            </div>
          </div>
          <Loading v-if="isLoading" />
          <div class="denglu" @click="login">登录</div>
          <div class="fangzhi">
            <div class="img2" @click="weixin">
              <img src="../../assets/login/wei.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Login_bottom />
    <el-button :plain="true" v-show="false"></el-button>
    <el-dialog :title="articleTypeName" :visible.sync="centerDialogVisible" width="50%" :before-close="handleClose" center>
      <div class="el-dialog-div"><span v-html="content"></span></div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="tongyi">同意并继续</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { post, get } from '@/utils/request'
import { mapMutations } from 'vuex'
import Login_bottom from '@/components/login/login_bottom.vue'
import { setCookie, getCookie, delCookie } from '@/utils/cookie'
import vueQr from 'vue-qr'
export default {
  name: 'Login',
  components: {
    Login_bottom,
    vueQr
  },
  data() {
    return {
      input: '',
      password: '',
      checked: false,
      isLoading: false,
      paihang: [
        {
          id: 1,
          title: '手机登录'
        },
        {
          id: 2,
          title: '密码登录'
        }
      ], // Tab页
      TabIndex: 0, // 默认选中Tab
      TabList: [], // Tab数据集合
      Register: {
        sendcode1: '',
        pass: '',
        phone: ''
      },

      disabled: false,
      time: 0,
      btntxt: '重新发送',
      uuid: '',
      centerDialogVisible: false,
      content: '',
      articleTypeName: '',
      Wechat_authorized_login: false,
      loding: false
    }
  },
  created() {
    this.selectContent()
  },
  // mounted() {
  //   this.get_wx_qrcode(); //获取微信的登录二维码
  // },
  methods: {
    weixin() {
      get('api/wxLogin/qrCodeUrl').then((res) => {
        window.open(res.data.msg, '_self')
      })
    },
    // 用户协议同意后执行
    tongyi() {
      this.centerDialogVisible = false
      let _this = this
      post('f/api/sms/smsRegister', {
        mobile: this.Register.phone,
        smsCode: this.Register.sendcode1,
        uuid: this.uuid
      }).then((res) => {
        if (res.data.code == 0) {
          _this.userToken = res.data.data.token
          _this.changeLogin({ Authorization: _this.userToken })
          // setCookie('Authorization',  res.data.data.token, 1800);
          setCookie('username', res.data.data.username, 7200)
          setCookie('memberId', res.data.data.memberId, 7200)
          setCookie('managerType', res.data.data.managerType, 7200)
          setCookie('companyName', res.data.data.companyName, 7200)
          if (res.data.data.companyName != null && res.data.data.companyName != '') {
            setTimeout(function () {
              _this.$router.push('/')
            }, 600)
          } else {
            //跳转公司名称维护页面
            setTimeout(function () {
              _this.$router.push({ name: 'updatecompanyname' })
            }, 600)
          }
          this.$message.success({
            message: '登录成功'
          })
        } else {
          this.$message.error({
            message: '登录失败'
          })
        }
      })
    },
    //用户协议
    selectContent() {
      get('f/api/articleType/selectById?id=7').then((res) => {
        this.articleTypeName = res.data.data.articleTypeName
        this.content = res.data.data.content
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.$router.push({
            name: 'Home'
          })
        })
        .catch((_) => {})
    },
    ...mapMutations(['changeLogin']),
    login() {
      if (this.TabIndex == 1) {
        this.passlogin()
      } else if (this.TabIndex == 0) {
        this.phonelogin()
      }
    },
    // 账号+密码 登录
    passlogin() {
      this.isLoading = true
      let _this = this
      if (this.input == '' || this.password == '') {
        this.isLoading = false
        this.$message.warning({
          message: '请填写账号或密码'
        })
      } else {
        let config = {
          headers: { 'Content-Type': 'application/json' }
        }
        post(
          'f/api/sms/login',
          {
            account: this.input,
            loginPassword: this.password
          },
          config
        ).then((res) => {
          this.isLoading = false
          if (res.data.code == 0) {
            _this.userToken = res.data.data.token
            _this.changeLogin({ Authorization: _this.userToken })
            // setCookie('Authorization',  res.data.data.token, 1800);
            setCookie('username', res.data.data.username, 7200)
            setCookie('memberId', res.data.data.memberId, 7200)
            setCookie('managerType', res.data.data.managerType, 7200)
            setCookie('companyName', res.data.data.companyName, 7200)
            if (res.data.data.companyName != null && res.data.data.companyName != '') {
              setTimeout(function () {
                _this.$router.push({ name: 'Home' })
              }, 600)
            } else {
              //跳转公司名称维护页面
              setTimeout(function () {
                _this.$router.push({ name: 'updatecompanyname' })
              }, 600)
            }
            this.$message.success({
              message: '登录成功'
            })
          } else {
            this.$message.error({
              message: res.data.msg
            })
          }
        })
      }
    },
    // 手机号+短信验证码 登录
    phonelogin() {
      let _this = this
      post('f/api/sms/smsLogin', {
        mobile: this.Register.phone,
        smsCode: this.Register.sendcode1,
        uuid: this.uuid
      }).then((res) => {
        if (res.data.code == 0) {
          _this.userToken = res.data.data.token
          _this.changeLogin({ Authorization: _this.userToken })
          setCookie('username', res.data.data.username, 7200)
          setCookie('memberId', res.data.data.memberId, 7200)
          setCookie('managerType', res.data.data.managerType, 7200)
          setCookie('companyName', res.data.data.companyName, 7200)
          if (res.data.data.companyName != null && res.data.data.companyName != '') {
            setTimeout(function () {
              _this.$router.push({ name: 'Home' })
            }, 600)
          } else {
            //跳转公司名称维护页面
            setTimeout(function () {
              _this.$router.push({ name: 'updatecompanyname' })
            }, 600)
          }
          this.$message.success({
            message: '登录成功'
          })
        } else if (res.data.msg == '验证码错误') {
          this.$message.error({
            message: '验证码输入错误'
          })
        } else if (res.data.msg == '运行时异常:手机号未注册') {
          this.centerDialogVisible = true
        } else {
          this.$message.error({
            message: '验证码无效'
          })
        }
      })
    },
    //发送验证码
    sendcode() {
      this.loding = true
      const reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      if (this.Register.phone == '') {
        this.loding = false
        this.$message.warning({
          message: '手机号不能为空'
        })
        return
      }
      if (!reg.test(this.Register.phone)) {
        this.loding = false
        this.$message.warning({
          message: '请输入正确的手机号'
        })
        return
      } else {
        get('f/api/sms/smsCode?mobile=' + this.Register.phone).then((res) => {
          this.loding = false
          if (res.data.msg == '短信发送成功') {
            this.$message.success({
              message: '发送成功'
            })
            this.uuid = res.data.uuid
            this.time = 60
            this.disabled = true
            this.timer()
          } else if (res.data.msg == '短信发送失败') {
            this.$message.warning({
              message: '发送失败'
            })
          } else {
            this.$message.warning({
              message: '发送失败'
            })
          }
        })
      }
    },
    // 发送成功后开启60S倒计时
    timer() {
      if (this.time > 0) {
        this.time--
        this.btntxt = this.time + 's后重新获取'
        setTimeout(this.timer, 1000)
      } else {
        this.time = 0
        this.btntxt = '获取验证码'
        this.disabled = false
      }
    },
    selectedTabItem(index, e) {
      this.TabIndex = index
    },
    home() {
      this.$router.push({
        name: 'Home'
      })
    },
    forget() {
      this.$router.push({
        name: 'Password'
      })
    },
    register() {
      this.$router.push({
        name: 'Register'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.wx_dialog_toiast {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9854647;
  background: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .wx_dialog_toiast_delltet {
    width: 500px;
    height: 500px;
    background: #606266;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wx_dialog_button_delete {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.code {
  .el-input {
    width: 65%;
    border-radius: 0px;
    float: left;
    display: inline-block;
  }
  .el-button {
    width: 35%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px;
    float: left;
    display: inline-block;
  }
}
.login_password {
  height: 400px;
}
.qiehuan {
  width: 390px;
  margin: auto;
  margin-top: 30px;
  height: 42px;
  display: flex;
  align-items: center;
  cursor: default;
  font-size: 20px;
  .daohang {
    font-size: 20px;
    color: #272727;
    margin-right: 25px;
    border-bottom: 2px solid #fff;
    cursor: pointer;
    padding-bottom: 3px;
  }
  .bian {
    color: #333;
    border-bottom: 2px solid #333;
  }
}
.header {
  width: 100%;
  height: 130px;

  .img {
    height: 66px;
    padding-top: 50px;
    // padding-left: 264px;
    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 230px;
      height: 66px;
    }
    .home {
      font-size: 20px;
      color: #245af2;
      font-weight: 600;
      margin-left: 27px;
      cursor: pointer;
    }
  }
}
.content {
  width: 100%;
  height: 680px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .login {
    width: 495px;
    height: 550px;
    background: #fff;
    position: absolute;
    right: 246px;
    bottom: 0;
    .top-logo {
      position: absolute;
      right: 12px;
      top: 12px;
      .img1 {
        width: 117px;
        height: 25px;
        float: left;
      }
      .img2 {
        width: 50px;
        height: 50px;
        float: left;
        margin-left: 5px;
      }
      .spen {
        font-size: 12px;
        position: absolute;
        left: 15px;
        top: 3px;
        color: #366eff;
      }
    }
    .user {
      width: 390px;
      margin: auto;
      // margin-top: 104px;
      margin-top: 40px;
    }
    .user-pas {
      width: 390px;
      margin: 20px auto;
    }
    .caozuo {
      width: 390px;
      margin: 0 auto;
      .left {
        float: left;
        color: #9a9a9a;
        font-size: 18px;
        display: flex;
        align-items: center;
      }
      .right {
        float: right;
        display: flex;
        justify-content: flex-end;
        .mima {
          color: #9a9a9a;
          margin-left: 11px;
          font-size: 16px;
          cursor: pointer;
        }
        .mima1 {
          color: #9a9a9a;
          margin-left: 11px;
          font-size: 16px;
          cursor: pointer;
        }
        .zhuce {
          color: #fe912a;
          margin-left: 11px;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
    .denglu {
      width: 390px;
      height: 55px;
      margin: 0 auto;
      background: #fe912a;
      color: #fff;
      font-size: 22px;
      line-height: 55px;
      display: flex;
      justify-content: center;
      margin-top: 130px;
      border-radius: 5px;
      cursor: pointer;
    }
    .fangzhi {
      width: 100%;
      position: absolute;
      bottom: 30px;
      display: flex;
      justify-content: center;
      .img1 {
        width: 44px;
        height: 44px;
      }
      .img2 {
        width: 44px;
        height: 44px;
      }
      .img3 {
        width: 44px;
        height: 44px;
      }
    }
  }
}
</style>
